import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components/';
import {
  Autocomplete,
  Button,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

/**
 * @function MaterialCommonPrice
 * @description Display a collections or a list of MaterialCommonPrice from database
 */

export default function MaterialLumpSumLaborCost({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [selectedMaterialType, setSelectedMaterialType] = useState([]);
  const [total, setTotal] = useState(0);
  const browserQuery = useQuery();
  const sendedMaterialType = _.find(materialType?.rows, {
    _id: browserQuery.get('material-type'),
  });

  const queryDataFromServer = async () => {
    try {
      if (browserQuery.get('material-type')) {
        dispatch(
          actions.materialAll({
            page,
            size,
            name,
            selectMaterialType: browserQuery.get('material-type'),
          }),
        );
      } else {
        const selectedMaterialTypeId = _.map(
          selectedMaterialType,
          (each) => each?._id,
        );
        const selectedMaterialTypeWithComma = selectedMaterialTypeId.join(',');
        dispatch(
          actions.materialAll({
            page,
            size,
            name,
            selectMaterialType: !_.isEmpty(selectedMaterialTypeId)
              ? selectedMaterialTypeWithComma
              : '',
          }),
        );
      }
    } catch (error) {
      dispatch(actions.materialError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name, selectedMaterialType]);

  useEffect(() => {
    dispatch(
      actions.materialTypeAll({
        page: 1,
        size: config.maxFetchSize,
      }),
    );
    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(material?.total);

    return () => {};
  }, [material]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div>
            <Link
              to={`labor-cost/edit?page=${page}&size=${size}&material-type=${
                browserQuery.get('material-type')
                  ? browserQuery.get('material-type')
                  : _.map(selectedMaterialType, (each) => each?._id).join(',')
              }`}
            >
              <Button variant="contained" color="warning">
                แก้ไข
              </Button>
            </Link>
          </div>
        </div>
        <div className="my-2">
          <Card>
            <div className="p-2">
              <div className="flex flex-wrap items-center">
                <div className="w-full lg:w-1/2 pr-2">
                  <TextField
                    size="small"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                  />
                </div>
                {!browserQuery.get('material-type') ? (
                  <div className="w-full lg:w-1/4">
                    {!_.isEmpty(materialType?.rows) && (
                      <Autocomplete
                        disablePortal
                        options={materialType?.rows}
                        size="small"
                        onChange={(e, newValue) => {
                          setSelectedMaterialType(newValue);
                        }}
                        multiple
                        className="z-40"
                        // prettier-ignore
                        getOptionLabel={(option) => `${option?.name || ''}`
                    }
                        renderInput={(params) => (
                          <TextField label="เลือกประเภทวัตถุดิบ" {...params} />
                        )}
                      />
                    )}
                  </div>
                ) : (
                  <div className="text-center">
                    ประเภทวัตถุดิบ : {sendedMaterialType?.name}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div>
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">รหัสวัตถุดิบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ชื่อวัตถุดิบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ต้นทุนค่าแรงต่อหน่วย</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">หน่วย</div>
                    </TableCell>{' '}
                    <TableCell>
                      <div className="font-bold">เวลาเฉลี่ยต่อหน่วย</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ประเภทวัตถุดิบ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(material?.rows) ? (
                    _.map(material?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">
                            {(page - 1) * size + index + 1}
                          </div>
                        </TableCell>
                        <TableCell>{row?.type_code}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>{row?.unit_labor_cost || '-'} บาท</TableCell>
                        <TableCell>
                          {row?.use_unit_conversion ? (
                            <div>
                              หลัก:{row?.unit_input?.name} <br />
                              รอง:{row?.unit_input?.name}
                            </div>
                          ) : (
                            row?.unit
                          )}
                        </TableCell>
                        <TableCell>
                          {row?.average_unit_time || '-'} นาที
                        </TableCell>
                        <TableCell>{row?.material_type?.name} </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={(e, newPage) => setPage(newPage + 1)}
              onRowsPerPageChange={(e) => setSize(e.target.value)}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialLumpSumLaborCost.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialLumpSumLaborCost.defaultProps = {
  title: '',
  subtitle: '',
};
