import React from 'react';

function LaborCostTDABC({
  previousLotList,
  currentLot,
  isCurrentAsProductLot,
}) {
  return <div>LaborCostTDABC</div>;
}

export default LaborCostTDABC;
