import React from 'react';
import { LABOR_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import PropTypes from 'prop-types';

import LaborCostDivideByWeight from './LaborCostDivideByWeight';
import LaborCostLumpsum from './LaborCostLumpsum';
import LaborCostTDABC from './LaborCostTDABC';

const LaborCostEstimation = ({
  method,
  previousLotList,
  place,
  currentLot,
  isCurrentAsProductLot,
}) => {
  switch (method) {
    case LABOR_COST_ESTIMATION_METHOD.TDABC.status_code:
      return (
        <LaborCostTDABC
          previousLotList={previousLotList}
          place={place}
          currentLot={currentLot}
          isCurrentAsProductLot={isCurrentAsProductLot}
        />
      );
    case LABOR_COST_ESTIMATION_METHOD.DIVIDE_BY_LOT_WEIGHT.status_code:
      return (
        <LaborCostDivideByWeight
          previousLotList={previousLotList}
          place={place}
          currentLot={currentLot}
          isCurrentAsProductLot={isCurrentAsProductLot}
        />
      );
    case LABOR_COST_ESTIMATION_METHOD.LUMPSUM_BY_MATERIAL.status_code:
      return (
        <LaborCostLumpsum
          previousLotList={previousLotList}
          place={place}
          currentLot={currentLot}
          isCurrentAsProductLot={isCurrentAsProductLot}
        />
      );
    default:
      return <div></div>;
  }
};

export default LaborCostEstimation;

LaborCostEstimation.propTypes = {
  method: PropTypes.string,
  previousLotList: PropTypes.array,
  place: PropTypes.object,
  currentLot: PropTypes.object,
  isCurrentAsProductLot: PropTypes.bool,
};
