/* eslint-disable arrow-body-style */
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import CapitalCost from './CapitalCost';
import LaborCost from './LaborCost';
import MaterialCost from './MaterialCost';

const CostProfileRendering = ({
  costEstimatioProfile,
  previousLotList,
  currentLot,
  isCurrentAsProductLot,
}) => {
  return (
    <div>
      {_.map(costEstimatioProfile?.warehouses, (profile, index) => {
        const isNonOfOperation =
          !profile?.material?.enable &&
          !profile?.labor?.enable &&
          !profile?.capital_cost?.enable;
        return (
          <div key={index} className="my-2">
            <Card>
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: 'text.secondary', fontSize: 14 }}
                >
                  {profile?.use_warehouse_instead
                    ? profile?.warehouse?.name
                    : profile?.place?.name}
                </Typography>
                {isNonOfOperation && <div>ไม่มีการคิดต้นทุนที่คลังนี้</div>}
                {profile?.material?.enable && (
                  <MaterialCost
                    method={profile?.material?.method}
                    useCostFromPreviousMaterial={
                      profile?.material?.use_cost_from_pervious_material
                    }
                    previousLotList={previousLotList}
                    place={profile?.place}
                  />
                )}

                {profile?.labor?.enable && (
                  <LaborCost
                    method={profile?.labor?.method}
                    previousLotList={previousLotList}
                    place={profile?.place}
                    currentLot={currentLot}
                    isCurrentAsProductLot={isCurrentAsProductLot}
                  />
                )}

                {profile?.capital_cost?.enable && (
                  <CapitalCost
                    method={profile?.capital_cost?.method}
                    enableElement={profile?.capital_cost?.element}
                    previousLotList={previousLotList}
                    place={profile?.place}
                    currentLot={currentLot}
                    isCurrentAsProductLot={isCurrentAsProductLot}
                  />
                )}
              </CardContent>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default CostProfileRendering;

CostProfileRendering.propTypes = {
  costEstimatioProfile: PropTypes.shape({
    warehouses: PropTypes.arrayOf(PropTypes.object),
  }),
  previousLotList: PropTypes.array,
};
