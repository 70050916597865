import api from '../../../configs/api';
import {
  CUSTOMER_ALL,
  CUSTOMER_DEL,
  CUSTOMER_ERROR,
  CUSTOMER_GET,
  CUSTOMER_LOADING,
  CUSTOMER_POST,
  CUSTOMER_PUT,
  CUSTOMER_RESET,
} from '../types';

export const customerCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/customer`,
      payload,
    );
    dispatch({ type: CUSTOMER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CUSTOMER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const customerAll = ({
  name = '',
  size = '',
  page = 1,
  customerTypeName = '',
  selectCustomerType = '',
  isSupplier = '',
  isCustomer = '',
  status: customerStatus = '',  
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/customer?name=${name}&size=${size}&page=${page}&customerTypeName=${customerTypeName}&customerTypeId=${selectCustomerType}&isSupplier=${isSupplier}&isCustomer=${isCustomer}&status=${customerStatus}`,
    );
    if (status === 200) {
      dispatch({ type: CUSTOMER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CUSTOMER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const customerGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/customer/${id}`,
    );
    if (status === 200) {
      dispatch({ type: CUSTOMER_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CUSTOMER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const customerPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/customer/${id}`,
      payload,
    );
    dispatch({ type: CUSTOMER_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CUSTOMER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
export const customerDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/customer/${id}`,
    );
    dispatch({ type: CUSTOMER_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CUSTOMER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const customerStateSet = (data) => async (dispatch) => {
  dispatch({ type: CUSTOMER_ALL, payload: data });
};

export const customerReset = () => async (dispatch) => {
  try {
    dispatch({ type: CUSTOMER_RESET });
  } catch (error) {
    console.error(error);
    dispatch({ type: CUSTOMER_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
