/* eslint-disable arrow-body-style */
import React from 'react';
import { CAPITAL_COST_ESTIMATION_METHOD } from '@iarcpsu/emanufac-constant';
import PropTypes from 'prop-types';

import CapitalCostDivideByWeight from './CapitalCostDivideByWeight';
import CapitalCostTDABC from './CapitalCostTDABC';

const CapitalCostEstimation = ({
  method,
  enableElement,
  previousLotList,
  place,
  currentLot,
  isCurrentAsProductLot,
}) => {
  switch (method) {
    case CAPITAL_COST_ESTIMATION_METHOD.TDABC.status_code:
      return (
        <CapitalCostTDABC
          enableElement={enableElement}
          previousLotList={previousLotList}
          place={place}
          currentLot={currentLot}
          isCurrentAsProductLot={isCurrentAsProductLot}
        />
      );
    case CAPITAL_COST_ESTIMATION_METHOD.DIVIDE_BY_LOT_WEIGHT.status_code:
      return (
        <CapitalCostDivideByWeight
          enableElement={enableElement}
          previousLotList={previousLotList}
          place={place}
          currentLot={currentLot}
          isCurrentAsProductLot={isCurrentAsProductLot}
        />
      );
    default:
      return <div></div>;
  }
};

export default CapitalCostEstimation;

CapitalCostEstimation.propTypes = {
  method: PropTypes.string,
  enableElement: PropTypes.object,
  previousLotList: PropTypes.array,
  place: PropTypes.object,
};
